import { Transition } from '@tailwindui/react';
import { Button } from 'shared/ui/Button';
import { Link } from 'shared/ui/Link';
import { useRef, useState } from 'react';
import useClickAway from 'react-use/lib/useClickAway';
import { CovidBanner } from './CovidBanner';
import { HeaderV2 } from './HeaderV2';
import { LogoLink } from './ui/LogoLink';

export function Header() {
  const versionHeader = 'v2'; //useExperiment(getHeaderVersion, 'v1');
  return versionHeader === 'v1' ? <HeaderV1 /> : <HeaderV2 />;
}

function HeaderV1() {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);
  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsOpen(false);
  });

  return (
    <>
      <CovidBanner />
      <div ref={ref} className="relative bg-gray-50">
        <div className="relative bg-white shadow-md">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center py-0 md:justify-start md:space-x-10">
              <div className="w-0 flex-1 flex">
                <LogoLink />
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <button
                  onClick={handleToggle}
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-900 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition"
                >
                  {/* Heroicon name: menu */}
                  <svg
                    className="h-8 w-8"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
              <nav className="hidden md:flex space-x-10 text-base font-bold">
                <Link color="header" href="/choose">
                  Nasze boxy
                </Link>
                <Link color="header" href="/delivery">
                  Dostawa i płatność
                </Link>
                <Link color="header" href="/reviews">
                  Opinie klientów
                </Link>
              </nav>
              <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
                <Button href="/choose">Wybierz swój box</Button>
              </div>
            </div>
          </div>

          {/* Mobile menu, show/hide based on menu open state. */}
          <Transition
            show={isOpen}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div
                ref={ref}
                className="absolute top-0 inset-x-0 z-50 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-lg">
                  <div className="rounded-lg bg-white divide-y-2 divide-gray-50 ring-1 ring-black ring-opacity-5">
                    <div className="pt-0 pb-6 px-5 space-y-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <LogoLink />
                        </div>
                        <div className="-mr-2">
                          <button
                            onClick={handleToggle}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                          >
                            {/* Heroicon name: x */}
                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div>
                        <nav className="grid gap-y-8">
                          <Link
                            href="/delivery"
                            className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition"
                          >
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-orange-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                fill="#fff"
                                d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                              />
                            </svg>
                            <div className="text-base leading-6 font-medium text-gray-900">
                              Dostawa i płatność
                            </div>
                          </Link>
                          <Link
                            href="/choose"
                            className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition"
                          >
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-orange-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                              />
                            </svg>
                            <div className="text-base leading-6 font-medium text-gray-900">
                              Nasze boxy
                            </div>
                          </Link>
                          {
                            // Disabled untill we do sourcing again.
                            false && (
                              <Link
                                href="/sourcing"
                                className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition"
                              >
                                {/* Heroicon name: shield-check */}
                                <svg
                                  className="flex-shrink-0 h-6 w-6 text-orange-600"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                  />
                                </svg>
                                <div className="text-base leading-6 font-medium text-gray-900">
                                  Nasi dostawcy
                                </div>
                              </Link>
                            )
                          }
                          <Link
                            href="/products"
                            className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition"
                          >
                            {/* Heroicon name: view-grid */}
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-orange-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                              />
                            </svg>
                            <div className="text-base leading-6 font-medium text-gray-900">
                              Nasze produkty
                            </div>
                          </Link>
                          <Link
                            href="/about"
                            className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition"
                          >
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-orange-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <div className="text-base leading-6 font-medium text-gray-900">
                              O nas
                            </div>
                          </Link>
                        </nav>
                      </div>
                    </div>
                    <div className="py-6 px-5 space-y-6">
                      <div className="grid grid-cols-2 gap-y-4 gap-x-8 text-base font-medium text-gray-900">
                        <Link
                          href="/faq"
                          className="leading-6 hover:text-gray-700 transition"
                        >
                          FAQ
                        </Link>
                        <Link
                          href="/contact"
                          className="leading-6 hover:text-gray-700 transition"
                        >
                          Pomoc
                        </Link>
                        <Link
                          href="/contact"
                          className="leading-6 hover:text-gray-700 transition"
                        >
                          Kariera
                        </Link>
                        <Link
                          href="/contact"
                          className="leading-6 hover:text-gray-700 transition"
                        >
                          Media i prasa
                        </Link>
                        <Link
                          href="/reviews"
                          className="leading-6 hover:text-gray-700 transition"
                        >
                          Opinie klientów
                        </Link>
                        <Link
                          href="/contact"
                          className="leading-6 hover:text-gray-700 transition"
                        >
                          Kontakt
                        </Link>
                      </div>
                      <div className="space-y-6">
                        <Button fullWidth href="/choose">
                          Wybierz swój box
                        </Button>
                        {/* <p className="text-center text-base leading-6 font-medium text-gray-500">
                          Existing customer?
                          <Link className="text-green-600 hover:text-green-500 transition">
                            Sign in
                          </Link>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Transition>
        </div>
      </div>
    </>
  );
}
