import { Transition } from '@tailwindui/react';
import { FACEBOOK_URL, INSTAGRAM_URL } from 'shared/constants';
import { Button } from 'shared/ui/Button';
import { Link } from 'shared/ui/Link';
import { useRef, useState } from 'react';
import useClickAway from 'react-use/lib/useClickAway';
import { getSmallBoxMinOrderFlow, useExperiment } from '../client/experiments';
import { PostHeader } from './PostHeader';
import { PreHeader } from './PreHeader';
import { LogoLink } from './ui/LogoLink';

export function HeaderV2() {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);
  const ref = useRef(null);
  useClickAway(ref, () => {
    setIsOpen(false);
  });
  const smallBoxMinOrderFlow = useExperiment(getSmallBoxMinOrderFlow, 'v1');

  return (
    <>
      <PreHeader />
      <div ref={ref} className="relative bg-gray-50">
        <div className="relative bg-white shadow-md">
          <div className="max-w-7xl mx-auto px-4 md:px-6">
            <div className="grid grid-cols-3 md:flex justify-between items-center py-0 md:justify-start md:space-x-10">
              <div className="flex-1 flex order-2 md:order-1">
                <LogoLink />
              </div>
              <div className="-ml-2 -my-2 md:hidden order-1 md:order-2">
                <button
                  onClick={handleToggle}
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-900 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition"
                >
                  {/* Heroicon name: menu */}
                  <svg
                    className="h-8 w-8"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
              <nav className="hidden md:flex space-x-10 text-base font-bold order-3">
                <Link color="header" href="/products">
                  Co jest w boxie?
                </Link>
                <Link color="header" href="/choose">
                  Nasze boxy
                </Link>
                <Link color="header" href="/reviews">
                  Opinie klientów
                </Link>
              </nav>
              <div className="flex md:flex-1 space-x-4 justify-end order-4">
                <Link
                  href={FACEBOOK_URL}
                  external={true}
                  className="text-gray-600 hover:text-gray-900 font-medium"
                >
                  <span className="sr-only">Facebook</span>
                  <svg
                    className="h-8 w-8"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Link>
                <Link
                  href={INSTAGRAM_URL}
                  external={true}
                  className="text-gray-600 hover:text-gray-900 font-medium"
                >
                  <span className="sr-only">Instagram</span>
                  <svg
                    className="h-8 w-8"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Link>
              </div>
              <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0 order-5">
                <Button href="/choose">Wybierz swój box</Button>
              </div>
            </div>
          </div>

          {/* Mobile menu, show/hide based on menu open state. */}
          <Transition
            show={isOpen}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div
                ref={ref}
                className="absolute top-0 inset-x-0 z-50 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-lg">
                  <div className="rounded-lg bg-white divide-y-2 divide-gray-50 ring-1 ring-black ring-opacity-5">
                    <div className="pt-0 pb-6 px-5 space-y-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <LogoLink />
                        </div>
                        <div className="-mr-2">
                          <button
                            onClick={handleToggle}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                          >
                            {/* Heroicon name: x */}
                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div>
                        <nav className="grid gap-y-8">
                          <Link
                            href="/delivery"
                            className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition"
                          >
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-orange-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                fill="#fff"
                                d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
                              />
                            </svg>
                            <div className="text-base leading-6 font-medium text-gray-900">
                              Dostawa i płatność
                            </div>
                          </Link>
                          <Link
                            href="/choose"
                            className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition"
                          >
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-orange-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                              />
                            </svg>
                            <div className="text-base leading-6 font-medium text-gray-900">
                              Nasze boxy
                            </div>
                          </Link>
                          {
                            // Disabled untill we do sourcing again.
                            false && (
                              <Link
                                href="/sourcing"
                                className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition"
                              >
                                {/* Heroicon name: shield-check */}
                                <svg
                                  className="flex-shrink-0 h-6 w-6 text-orange-600"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                                  />
                                </svg>
                                <div className="text-base leading-6 font-medium text-gray-900">
                                  Nasi dostawcy
                                </div>
                              </Link>
                            )
                          }
                          <Link
                            href="/products"
                            className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition"
                          >
                            {/* Heroicon name: view-grid */}
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-orange-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                              />
                            </svg>
                            <div className="text-base leading-6 font-medium text-gray-900">
                              Nasze produkty
                            </div>
                          </Link>
                          <Link
                            href="/about"
                            className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition"
                          >
                            <svg
                              className="flex-shrink-0 h-6 w-6 text-orange-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            <div className="text-base leading-6 font-medium text-gray-900">
                              O nas
                            </div>
                          </Link>
                        </nav>
                      </div>
                    </div>
                    <div className="py-6 px-5 space-y-6">
                      <div className="grid grid-cols-2 gap-y-4 gap-x-8 text-base font-medium text-gray-900">
                        <Link
                          href="/faq"
                          className="leading-6 hover:text-gray-700 transition"
                        >
                          FAQ
                        </Link>
                        <Link
                          href="/contact"
                          className="leading-6 hover:text-gray-700 transition"
                        >
                          Pomoc
                        </Link>
                        <Link
                          href="/contact"
                          className="leading-6 hover:text-gray-700 transition"
                        >
                          Kariera
                        </Link>
                        <Link
                          href="/contact"
                          className="leading-6 hover:text-gray-700 transition"
                        >
                          Media i prasa
                        </Link>
                        <Link
                          href="/reviews"
                          className="leading-6 hover:text-gray-700 transition"
                        >
                          Opinie klientów
                        </Link>
                        <Link
                          href="/contact"
                          className="leading-6 hover:text-gray-700 transition"
                        >
                          Kontakt
                        </Link>
                      </div>
                      <div className="space-y-6">
                        <Button fullWidth href="/choose">
                          Wybierz swój box
                        </Button>
                        {/* <p className="text-center text-base leading-6 font-medium text-gray-500">
                          Existing customer?
                          <Link className="text-green-600 hover:text-green-500 transition">
                            Sign in
                          </Link>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Transition>
        </div>
      </div>
      {smallBoxMinOrderFlow === 'v2' && <PostHeader />}
    </>
  );
}
